import React from 'react';
import { Card } from 'react-bootstrap'
import './RockCard.css';
import { Rock } from '../../rock/Rock';

export interface RockCardProps {
  rock: Rock;
}

export class RockCard extends React.Component<RockCardProps> {

  render() {
    return (
      <div className='card-container'>
        <Card className='shadow-lg' style={{ width: '100%' }}>
          <div className='card-image'>
            <Card.Img variant="top" src={this.props.rock.backgroundImage} />
            <img className='overlay-image' width='70%' src={this.props.rock.displayImage} alt={''}/>
            <h1 className='overlay-name'>{this.props.rock.name}</h1>
          </div>
          <Card.Text className='text-left'>
            <div className='card-attribute'><b>Age:</b> {this.props.rock.age}</div>
            <div className='card-attribute'><b>Weight:</b> {this.props.rock.weight}</div>
            <div className='card-attribute'><b>Nature:</b> {this.props.rock.nature}</div>
            <div className='card-attribute'><b>Favorite Food:</b> {this.props.rock.food}</div>
            <div className='card-attribute'><b>Interests:</b> {this.props.rock.interests.join(', ')}</div>
            <div className='card-attribute'><b>Special Trick:</b> {this.props.rock.trick}</div>
          </Card.Text>
        </Card>
      </div>
    )
  }
}