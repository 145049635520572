import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Rock } from '../../rock/Rock';
import { RockCard } from '../RockCard/RockCard';
import './RockCardGenerator.css';

interface RockCardGeneratorState {
  rock: Rock
}

export interface RockCardGeneratorProps {}

export class RockCardGenerator extends React.Component<RockCardGeneratorProps, RockCardGeneratorState> {

  constructor(props: RockCardGeneratorProps) {
    super(props);
    this.state = {
      rock: new Rock()
    };
  }

  getNewRock = () => {
    this.setState({
      rock: new Rock()
    })
  }

  render() {
    return (
      <div className='card-generator-container'>
        <RockCard rock={this.state.rock}></RockCard>
        <Button className="rounded-0" onClick={this.getNewRock} variant="sand" size="lg" block>
          Next Rock
        </Button>
      </div>
    )
  }
}