import {
  getRandomNumberBetween,
  getRandomIntBetween,
  getNumberOfRandom
} from '../utils/Utils';
import RockAttributes from './RockAttributes.json';
import rock from '../assets/main-rock.png';

// Background Images
import bar from '../assets/backgrounds/bar-background.jpg';
import beach from '../assets/backgrounds/beach-background.jpg';
import brazil from '../assets/backgrounds/brazil-background.jpg';
import city from '../assets/backgrounds/city-background.jpg';
import field from '../assets/backgrounds/field-background.jpg';
import japan from '../assets/backgrounds/japan-background.jpg';
import leaves from '../assets/backgrounds/leaves-background.jpg';
import road from '../assets/backgrounds/road-background.jpg';
import snow from '../assets/backgrounds/snow-background.jpg';

export class Rock {
  _id?: string;
  name: string;
  age: string;
  weight: string;
  nature: string;
  trick: string;
  food: string;
  interests: string[];
  displayImage: string;
  backgroundImage: string;

  constructor() {
    this.name = getRandomName();
    this.age = getRandomAge();
    this.weight = getRandomNumberBetween(1, 25).toFixed(2) + ' kg';
    this.nature = getRandomNature();
    this.trick = getRandomTrick();
    this.food = getRandomFood();
    this.interests = getRandomInterests(25);
    this.displayImage = rock;
    this.backgroundImage = getRandomBackgroundImage();
  }
}

function getRandomName(): string {
  return getNumberOfRandom(RockAttributes.Names, 1)[0];
}

function getRandomAge(): string {
  return `${getRandomNumberBetween(1, 100).toFixed(1)}M years`;
}

function getRandomNature(): string {
  return getNumberOfRandom(RockAttributes.Natures, 1)[0];
}

function getRandomTrick(): string {
  return getNumberOfRandom(RockAttributes.Tricks, 1)[0];
}

function getRandomFood(): string {
  return getNumberOfRandom(RockAttributes.Foods, 1)[0];
}

function getRandomInterests(maxChars: number): string[] {
  let list: string[];
  do {
    list = getNumberOfRandom(RockAttributes.Interests, 2)
  } while(list.join().length >= maxChars);
  return list;
}

function getRandomBackgroundImage(): string {
  const backgrounds: Array<string> = [
    bar,
    beach,
    brazil,
    city,
    field,
    japan,
    leaves,
    road,
    snow
  ]
  return backgrounds[getRandomIntBetween(0, backgrounds.length - 1)];
}